import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../SibeBar";
import { Button, Col, Container, Form, Row, Spinner } from "reactstrap";
import { UtilContext } from "../../contexts/UtilContext";
import useAxiosWrapper from "../../api/axiosWrapper";
import { useNavigate } from "react-router-dom";
import classes from "../Home/Home.module.css";
import { EditableField } from "../Profile/Profile";
import { FaAngleLeft } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

function UpdateCharity() {
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [confirmCharityState, setConfirmCharityState] = useState(false);

  const { setSelectedCharity, selectedCharity, setUsername, email, username } =
    useContext(UtilContext);
  const axiosInstance = useAxiosWrapper();
  const [suggestedCharitites, setSuggestedCharities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);
      if (!res?.name) {
        localStorage.removeItem("user-ai");
        navigate("/signin");
      } else {
        setUsername(res?.name);
      }
      if (res?.charity) {
        setSelectedCharity(res?.charity);
        //   navigate("/create-campaign");
      }
    } else {
      navigate("/signin");
    }
  }, []);

  const handleChange = (e) => {
    setSelectedCharity(null);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setSuggestedCharities([]);
    let delayDebounceFn;
    if (!inputValue || !!selectedCharity) {
      clearTimeout(delayDebounceFn);
      return;
    } else {
      delayDebounceFn = setTimeout(() => {
        setLoading(true);
        axiosInstance
          .get(
            process.env.REACT_APP_DO_GOOD_URL + `/charity/list/${inputValue}`
          )
          .then((result) => {
            const data = result?.data?.data?.slice(0, 7);
            setSuggestedCharities(data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }, 1000);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, selectedCharity]);

  return (
    <SideBar>
      <Container>
        <Row className="justify-content-center mt-4">
          <Col sm={12} className="d-flex justify-content-end">
            <div
              className="ml-4 btn d-flex align-items-center justify-content-center"
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: "#eff0f6",
              }}
              onClick={() => navigate(-1)}
            >
              <div>
                <MdClose />
              </div>
            </div>
          </Col>
          {editable ? (
            <Col md={5}>
              <>
                <>
                  <h5>Let's Update Your Charity</h5>
                  <p>Let's get started by finding your charity.</p>
                </>
                <div>
                  <div>
                    <div className="form-floating mb-3 mt-4 mr-3 w-100">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Enter text"
                        value={selectedCharity?.name || inputValue}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <label htmlFor="floatingInput">Enter Charity Name</label>
                    </div>
                  </div>
                  {!!suggestedCharitites?.length ? (
                    <div className={classes.suggestedCtr}>
                      {suggestedCharitites.map((item, index) => {
                        return (
                          <div
                            style={{ cursor: "pointer" }}
                            className={`${
                              item?.id == selectedId && "bg-gray"
                            } hover-bg-gray p-3 m-0`}
                            key={index}
                            onClick={() => {
                              setSelectedCharity(item);
                              setselectedId(item.id);
                              localStorage.setItem(
                                "user-ai",
                                JSON.stringify({
                                  name: username,
                                  charity: item,
                                  email: email,
                                })
                              );
                            }}
                          >
                            <div>{item?.name}</div>
                            <span style={{ color: "#a0a3bd" }}>
                              {item?.location?.city}{" "}
                              {item?.location?.city ? "," : ""}{" "}
                              {item?.location?.state}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      <div style={{ height: 250 }}>
                        <div style={{ textAlign: "center" }} className="pt-3">
                          {loading && <Spinner />}
                        </div>
                      </div>
                    </>
                  )}
                  <Button
                    disabled={!selectedCharity}
                    style={{
                      backgroundColor: !selectedCharity ? "gray" : "black",
                      marginTop: 30,
                    }}
                    className="w-100"
                    onClick={() => {
                      setEditable(false);
                      setConfirmCharityState(true);
                    }}
                  >
                    {" "}
                    Next
                  </Button>
                </div>
              </>
            </Col>
          ) : (
            <>
              {confirmCharityState ? (
                <Col md={5}>
                  <>
                    <>
                      <h5>Confirm your charity</h5>
                      <p>Found you! is this your charity?</p>
                    </>
                    <div className={`rounded-border mb-3 mt-4 ${classes.name}`}>
                      <b>{selectedCharity?.name}</b>
                      <div style={{ fontSize: 12, color: "gray" }}>
                        {selectedCharity?.location?.city}{" "}
                        {selectedCharity?.location?.city ? "," : ""}{" "}
                        {selectedCharity?.location?.state}
                      </div>
                      <div className="mt-3" style={{ fontSize: 13 }}>
                        {selectedCharity?.mission}
                      </div>
                    </div>
                    <Button
                      outline
                      className="w-100 mt-4"
                      onClick={() => {
                        localStorage.setItem(
                          "user-ai",
                          JSON.stringify({
                            name: username,
                            charity: "",
                            email,
                          })
                        );
                        setConfirmCharityState(false);
                        setEditable(true);
                      }}
                    >
                      {" "}
                      Not My Charity
                    </Button>
                    <Button
                      style={{ backgroundColor: "black" }}
                      className="w-100 mt-3"
                      onClick={() => {
                        setEditable(false);
                        setConfirmCharityState(false);
                      }}
                    >
                      {" "}
                      Next
                    </Button>
                  </>
                </Col>
              ) : (
                <>
                  <Col md={8}>
                    <>
                      <h5 className="mb-5">Charity</h5>
                      <Form className="row gap-2">
                        <div
                          className={`rounded-border mb-3 col-8  ${classes.name}`}
                        >
                          <b>{selectedCharity?.name}</b>
                          <div style={{ fontSize: 12, color: "gray" }}>
                            {selectedCharity?.location?.city}{" "}
                            {selectedCharity?.location?.city ? "," : ""}{" "}
                            {selectedCharity?.location?.state}
                          </div>
                          <div className="mt-3" style={{ fontSize: 13 }}>
                            {selectedCharity?.mission}
                          </div>
                        </div>
                        <div className="col-3 ml-2 text-end">
                          <Button
                            outline
                            className="text-center"
                            color="primary"
                            onClick={() => {
                              setEditable(true);
                            }}
                          >
                            Change Charity
                          </Button>
                        </div>
                      </Form>
                    </>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
      </Container>
    </SideBar>
  );
}

export default UpdateCharity;
