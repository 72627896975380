import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import * as animationData from "../../assets/animation.json";
import Lottie from "react-lottie";
import { isMobile } from "react-device-detect";

export default function Complete() {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Container>
      <Row className="justify-content-center align-items-center mt-5">
        <Col sm={12} className="text-center mt-5">
          <Lottie
            options={defaultOptions}
            className="mt-5"
            height={200}
            width={200}
          />
        </Col>
        <Col sm={12} className="text-center mt-5">
          <h4 className="mt-5">Campaign Complete!</h4>
          <span style={{ width: 200, display: "inline-block" }}>
            Congratulations on your first campaign.
          </span>
        </Col>
        <Col xs={12} className="text-center mt-5">
          <button
            onClick={() => navigate("/create-campaign")}
            className="btn btn-dark"
            style={{ width: isMobile ? 300 : 400, color: "white" }}
          >
            New Email
          </button>
        </Col>
      </Row>
    </Container>
  );
}
