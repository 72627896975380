import { Button, Col, Row } from "reactstrap";
import { useContext, useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./ConfirmCharity.module.css";
import { isMobile } from "react-device-detect";

function ConfirmCharity() {
  const navigate = useNavigate();
  const { selectedCharity, username, setSelectedCharity, setUsername, email } =
    useContext(UtilContext);

  useEffect(() => {
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);
      if (!res?.name) {
        navigate("/signin");
      } else {
        setUsername(res?.name);
      }
      if (res?.charity) {
        setSelectedCharity(res?.charity);
      }
    } else {
      navigate("/signin");
    }
  }, []);
  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col className="relative">
        <div
          style={{
            width: isMobile ? "auto" : 460,
            marginTop: isMobile ? 120 : 20,
          }}
          className="container"
        >
          <div onClick={() => navigate("/")} className="back">
            <FaAngleLeft />
          </div>
          <h3>Confirm Your Charity</h3>
          <p>Found you! Is this your charity?</p>
          <div className={`rounded-border mb-3 mt-4 ${classes.name}`}>
            <b>{selectedCharity?.name}</b>
            <div style={{ fontSize: 12, color: "gray" }}>
              {selectedCharity?.location?.city}{" "}
              {selectedCharity?.location?.city ? "," : ""}{" "}
              {selectedCharity?.location?.state}
            </div>
            <div className="mt-3" style={{ fontSize: 13 }}>
              {selectedCharity?.mission}
            </div>
          </div>
          <Button
            outline
            className="w-100 mt-4"
            onClick={() => {
              localStorage.setItem(
                "user-ai",
                JSON.stringify({
                  name: username,
                  charity: "",
                  email,
                })
              );
              navigate("/");
            }}
          >
            {" "}
            Not My Charity
          </Button>
          <Button
            style={{ backgroundColor: "black" }}
            className="w-100 mt-3"
            onClick={() => {
              navigate("/create-campaign");
              localStorage.setItem(
                "user-ai",
                JSON.stringify({
                  name: username,
                  charity: selectedCharity,
                  email,
                })
              );
            }}
          >
            {" "}
            Next
          </Button>
        </div>
      </Col>
      <Col className={`d-none d-md-flex ${classes.imgCtr}`}>
        <img src={"confirm.png"} alt="" className={classes.img} />
      </Col>
    </Row>
  );
}

export default ConfirmCharity;
