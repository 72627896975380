import { Button, Col, Row, Spinner } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UtilContext } from "../../contexts/UtilContext";
import useAxiosWrapper from "../../api/axiosWrapper";
import classes from "./Home.module.css";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

function Home() {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setSelectedCharity, selectedCharity, setUsername } =
    useContext(UtilContext);
  const axiosInstance = useAxiosWrapper();

  const [suggestedCharitites, setSuggestedCharities] = useState([]);
  const navigate = useNavigate();
  console.log(selectedCharity, "selected charity");
  const checkGoogleSign = () => {
    axiosInstance
      .get(process.env.REACT_APP_AI_DO_GOOD_URL + `/v1/user/me`)
      .then((result) => {
        console.log(result, "result");
        const response = result?.data?.data;
        if (response?.id) {
          const name = response?.first_name + " " + response?.last_name;
          setUsername(name);
          localStorage.setItem(
            "user-ai",
            JSON.stringify({
              name,
              charity: selectedCharity,
              email: response.email,
            })
          );
        } else {
          const userData = localStorage.getItem("user-ai");
          console.log(userData, "ud");
          if (!!userData) {
            const res = JSON.parse(userData);
            if (!res?.name) {
              localStorage.removeItem("user-ai");
              navigate("/signin");
            } else {
              setUsername(res?.name);
            }
            if (res?.charity) {
              setSelectedCharity(res?.charity);
              navigate("/create-campaign");
            }
          } else {
            navigate("/signin");
          }
        }
      })
      .catch((err) => {
        console.log(err, "dsd");
        localStorage.removeItem("user-ai");
        navigate("/signin");

        const userData = localStorage.getItem("user-ai");
        // console.log(userData, "ud");
        // if (!!userData) {
        //   const res = JSON.parse(userData);
        //   if (!res?.name) {
        //     localStorage.removeItem("user-ai");
        //     navigate("/signin");
        //   } else {
        //     setUsername(res?.name);
        //   }
        //   if (res?.charity) {
        //     setSelectedCharity(res?.charity);
        //     navigate("/create-campaign");
        //   }
        // } else {
        //   navigate("/signin");
        // }
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("home");
    checkGoogleSign();
  }, []);

  const handleChange = (e) => {
    setSelectedCharity(null);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setSuggestedCharities([]);
    let delayDebounceFn;
    if (!inputValue || !!selectedCharity) {
      clearTimeout(delayDebounceFn);
      return;
    } else {
      delayDebounceFn = setTimeout(() => {
        setLoading(true);
        axiosInstance
          .get(
            process.env.REACT_APP_DO_GOOD_URL + `/charity/list/${inputValue}`
          )
          .then((result) => {
            const data = result?.data?.data?.slice(0, 7);
            setSuggestedCharities(data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }, 1000);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, selectedCharity]);

  return (
    <>
      <Row className="justify-content-center align-items-center h-100">
        <Col className="">
          <div
            style={{
              width: isMobile ? "auto" : 460,
              marginTop: isMobile ? 120 : 20,
            }}
            className="container"
          >
            <h3>Welcome to DoGoodAI</h3>
            <p>Let's get started by finding your charity.</p>
            <div>
              <div className="form-floating mb-3 mt-4 mr-3 w-100">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Enter text"
                  value={selectedCharity?.name || inputValue}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <label htmlFor="floatingInput">Enter Charity Name</label>
              </div>
            </div>
            {!!suggestedCharitites?.length ? (
              <div className={classes.suggestedCtr}>
                {suggestedCharitites.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        item?.id == selectedId && "bg-gray"
                      } hover-bg-gray p-3 m-0`}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedCharity(item);
                        setselectedId(item.id);
                      }}
                    >
                      <div style={{ fontWeight: "600" }} key={index}>
                        {item?.name}
                      </div>
                      <span style={{ color: "#a0a3bd" }}>
                        {item?.location?.city} {item?.location?.city ? "," : ""}{" "}
                        {item?.location?.state}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <div style={{ height: 250 }}>
                  <div style={{ textAlign: "center" }} className="pt-3">
                    {loading && <Spinner />}
                  </div>
                </div>
              </>
            )}
            <Button
              disabled={!selectedCharity}
              style={{
                backgroundColor: !selectedCharity ? "gray" : "black",
                marginTop: 30,
              }}
              className="w-100"
              onClick={() => navigate("/confirm-charity")}
            >
              {" "}
              Next
            </Button>
          </div>
        </Col>
        <Col className={`d-none d-md-flex ${classes.imgCtr}`}>
          <img src={"intro.png"} alt="" style={{ width: 353, height: 260 }} />
        </Col>
      </Row>
    </>
  );
}

export default Home;
