import React, { useContext, useEffect, useState } from "react";
import { Button, Form, FormGroup, Col, Container, Row } from "reactstrap";
import "./FloatingLabel.css"; // Import the CSS file
import SideBar from "../../SibeBar";
import useAxiosWrapper from "../../api/axiosWrapper";
import { UtilContext } from "../../contexts/UtilContext";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";

export const EditableField = ({
  label,
  value,
  isEditing,
  onChange,
  onSave,
  onCancel,
  onEdit,
  key,
}) => {
  return (
    <FormGroup row className="align-items-center">
      <Col sm={isEditing ? 8 : 10}>
        <div className="floating-label-group">
          {isEditing ? (
            <input
              type={label === "Password" ? "password" : "text"}
              name={label}
              id={label}
              className="floating-label-input"
              value={value}
              onChange={onChange}
              placeholder=" "
            />
          ) : (
            <input
              type="text"
              name={label}
              id={label}
              className="floating-label-input read-only-input"
              value={label === "Password" ? "******" : value}
              readOnly
              placeholder=" "
            />
          )}
          <label className="floating-label" htmlFor={label}>
            {label}
          </label>
        </div>
      </Col>
      <Col sm={isEditing ? 4 : 2}>
        {isEditing ? (
          <>
            <Button color="primary" onClick={onSave}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            {label !== "Email" && (
              <Button outline color="primary" onClick={onEdit}>
                Edit
              </Button>
            )}
          </>
        )}
      </Col>
    </FormGroup>
  );
};

const ProfilePage = () => {
  const axiosInstance = useAxiosWrapper();
  const { selectedCharity, setSelectedCharity, setUsername, email } =
    useContext(UtilContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [fields, setFields] = useState({
    name: { value: "", isEditing: false },
    email: { value: "", isEditing: false },
    password: { value: "********", isEditing: false },
  });

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axiosInstance.get(
        process.env.REACT_APP_DO_GOOD_URL + "/user/me"
      );
      console.log(response?.data?.data, "user response");
      const userInfo = response?.data?.data;
      setFields({
        name: {
          value: userInfo?.first_name + " " + userInfo?.last_name,
          isEditing: false,
        },
        email: { value: userInfo?.email, isEditing: false },
        password: { value: userInfo?.password, isEditing: false },
      });
    } catch {
      setMessage("Something went wrong. Please try again");
    }
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => setMessage(null), 3000);
    }
  }, [message]);

  const updateUserInfo = async () => {
    try {
      const response = await axiosInstance.post(
        process.env.REACT_APP_DO_GOOD_URL + "/user/update",
        {
          first_name: fields?.name?.value?.split(" ")?.[0],
          last_name: fields?.name?.value?.split(" ")?.[1],
          email: fields?.email?.value,
          password: fields?.password?.value,
        }
      );
      localStorage.setItem(
        "user-ai",
        JSON.stringify({
          name:
            fields?.name?.value?.split(" ")?.[0] +
            " " +
            fields?.name?.value?.split(" ")?.[1],
          charity: selectedCharity,
          email: email,
        })
      );
      setUsername(
        fields?.name?.value?.split(" ")?.[0] +
          " " +
          fields?.name?.value?.split(" ")?.[1]
      );
      setMessage("User info updated successfully!");
    } catch (error) {
      setMessage("Something went wrong. Please try again");
    }
  };

  const handleEdit = (field) => {
    setFields({
      ...fields,
      [field]: { ...fields[field], isEditing: true },
    });
  };

  const handleChange = (field, event) => {
    setFields({
      ...fields,
      [field]: { ...fields[field], value: event.target.value },
    });
  };

  const handleSave = (field) => {
    setFields({
      ...fields,
      [field]: { ...fields[field], isEditing: false },
    });
    updateUserInfo();
  };

  const handleCancel = (field) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        isEditing: false,
        value: fields[field].value,
      },
    });
  };

  return (
    <SideBar>
      <Container>
        <Row className="justify-content-center mt-4">
          <Col sm={12} className="d-flex justify-content-end">
            <div
              className="ml-5 btn d-flex align-items-center justify-content-center"
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: "#eff0f6",
              }}
            >
              <div onClick={() => navigate("/create-campaign")}>
                <MdClose />
              </div>
            </div>
          </Col>
          <Col md={6}>
            {message && <div className="alert alert-info mt-3">{message}</div>}
            <Form>
              {Object.keys(fields).map((field) => (
                <EditableField
                  key={field}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={fields[field].value}
                  isEditing={fields[field].isEditing}
                  onChange={(e) => handleChange(field, e)}
                  onSave={() => handleSave(field)}
                  onCancel={() => handleCancel(field)}
                  onEdit={() => handleEdit(field)}
                />
              ))}
            </Form>
          </Col>
        </Row>
      </Container>
    </SideBar>
  );
};

export default ProfilePage;
